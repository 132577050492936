import { Injectable } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { Capacitor } from "@capacitor/core";
import { interval } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AlertAppService } from "./alert-app.service";

@Injectable({
  providedIn: 'root'
})

export class SwupdaterAppService {

  constructor(
    public swUpdate: SwUpdate,
    private alertAppSrv: AlertAppService,
  ) {
    if (swUpdate.isEnabled
      && Capacitor.getPlatform() == 'web') {
      // a cada 5 minutos
      interval(5 * 60000).subscribe(() => swUpdate.checkForUpdate()
        .then(() => {
          console.log(`checking for updates - ${new Date()}`)
        }));
    }
  }

  // public checkForUpdates(): void {
  //   this.swUpdate.versionUpdates.subscribe(event => this.promptUser());
  // }
  public checkForUpdates(): void {
    this.swUpdate.versionUpdates.
      pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))).subscribe(evt => {
          this.promptUser();
        });
  }

  private promptUser(): void {
    console.log('updating to new version');
    this.swUpdate.activateUpdate().then(async (res) => {
      // alert('here!');
      // document.location.reload()
      await this.alertAppSrv.toast(`O site será reiniciado devido a nova versão`, null, '', 3000);
      setTimeout(async () => { document.location.reload(); }, 3000, []);
    });
  }
}
