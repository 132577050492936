<ion-tabs>
  <ion-tab-bar *ngIf="isDesktop === false" slot="bottom" [ngClass]="{'statusOnline': _flOnline,
  'statusOffline': !_flOnline}" style="width: 100%;">
    <ion-tab-button class="buttonMenuMobile" tab="passeios" (click)="navegarPasseios()" *ngIf="_tpUsuario !== 1">
      <ion-icon name="home-sharp"></ion-icon>
      <ion-label>Passeios</ion-label>
    </ion-tab-button>
    <ion-tab-button class="buttonMenuMobile" tab="tabClientePedidos" *ngIf="_tpUsuario !== 1">
      <ion-icon name="book"></ion-icon>
      <ion-label>Pedidos</ion-label>
    </ion-tab-button>
    <ion-tab-button class="buttonMenuMobile" tab="tabPerfil" *ngIf="_tpUsuario !== 1">
      <ion-icon name="person"></ion-icon>
      <ion-label>Perfil</ion-label>
    </ion-tab-button>
    <ion-tab-button class="buttonMenuMobile" tab="tabGuiaHome" *ngIf="_tpUsuario === 1">
      <ion-icon name="book"></ion-icon>
      <ion-label>Pedidos</ion-label>
    </ion-tab-button>
    <ion-tab-button class="buttonMenuMobile" tab="passeios" (click)="navegarPasseios()" *ngIf="_tpUsuario === 1">
      <ion-icon name="home-sharp"></ion-icon>
      <ion-label>Passeios</ion-label>
    </ion-tab-button>
    <ion-tab-button class="buttonMenuMobile" tab="tabPerfil" *ngIf="_tpUsuario === 1">
      <ion-icon name="person"></ion-icon>
      <ion-label>Perfil</ion-label>
    </ion-tab-button>
    <ion-tab-button class="buttonMenuMobile" tab="tabAjuda">
      <ion-icon name="help-circle"></ion-icon>
      <ion-label>Ajuda</ion-label>
    </ion-tab-button>
    <ion-tab-button class="buttonMenuMobile" tab="tabDummy">
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>

<div *ngIf="isDesktop === true" class="desktop">
  <ion-header>
    <ion-toolbar>
      <ion-row class="ion-align-items-center" style="z-index: 1; padding: 0px!important;">
        <ion-col size="0.5">
        </ion-col>
        <ion-col size="2.5" style="padding: 0px;" *ngIf="_tpUsuario !== 1">
          <ion-button tabindex="0" fill="clear" size="small" class="buttonLogo" color="favorite" (click)="Home()"
            (keyup.enter)="Home()" style="height: 65px; width: 195px!important;" title="Home">
            <img src="./assets/PasseioJaLogoLateral_Transparente.png" class="logo" alt="logotipo"
              title="Página inicial">
          </ion-button>
        </ion-col>
        <ion-col size="2.5" style="padding: 0px;" *ngIf="_tpUsuario === 1">
          <ion-button tabindex="0" fill="clear" size="small" class="buttonLogo" color="favorite"
            (click)="Navegar('/tabs/tabGuiaHome')" (keyup.enter)="Navegar('/tabs/tabGuiaHome')"
            style="height: 65px; width: 195px!important;" title="Home">
            <img src="./assets/PasseioJaLogoLateral_Transparente.png" class="logo" alt="logotipo"
              title="Página inicial">
          </ion-button>
        </ion-col>
        <ion-col size="2.7" size-md="0" size-lg="0" size-xl="2.7" *ngIf="_tpUsuario !== 1"
          style="padding-left: 0px; padding-right: 0px; padding-top: 10px; height: 60px;">
          <!-- <ion-title class="ion-text-center fraseTopo">Não somos uma agência de viagens,</ion-title>
          <ion-title class="ion-text-center fraseTopo">somos MUITAS!</ion-title> -->
          <ion-title class="ion-text-center fraseTopo">Seus passeios e excursões</ion-title>
          <ion-title class="ion-text-center fraseTopo">em uma única plataforma!</ion-title>
        </ion-col>
        <ion-col size="2.7" size-md="0" size-lg="0" size-xl="2.7" *ngIf="_tpUsuario === 1"
          style="padding-left: 0px; padding-right: 0px; padding-top: 10px; height: 60px;">
        </ion-col>
        <ion-col size="6.3" size-md="9" size-lg="9" size-xl="6.3" style="padding-top: 10px; height: 60px;">
          <div class="ion-text-right" style="padding: 0px;">
            <ion-button tabindex="0" fill="clear" size="small" (click)="NavegarPasseiosDesktop()"
              (keyup.enter)="NavegarPasseiosDesktop()" *ngIf="_tpUsuario !== 1" class="link" color="favorite">Passeios
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small" (click)="Navegar('/tabs/tabClientePedidos')"
              (keyup.enter)="Navegar('/tabs/tabClientePedidos')" *ngIf="_tpUsuario === 2" class="link" color="favorite">
              Pedidos
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small" (click)="Navegar('/tabs/tabGuiaHome')"
              (keyup.enter)="Navegar('/tabs/tabGuiaHome')" *ngIf="_tpUsuario === 1" class="link" color="favorite">
              Pedidos
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small"
              (click)="Navegar('/tabs/tabPerfil/perfilGuiaListaAvaliacoes')"
              (keyup.enter)="Navegar('/tabs/tabPerfil/perfilGuiaListaAvaliacoes')" class="link" color="favorite"
              *ngIf="_tpUsuario === 1">
              Avaliações
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small" class="link" *ngIf="_tpUsuario !== 1"
              style="margin-left: 15px !important;" (click)="NavegarSiteBlog()" (keyup.enter)="NavegarSiteBlog()">Blog
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small"
              *ngIf="_tpUsuario !== 1 && _flApresentarComoFunciona === true"
              (click)="Navegar('/tabs/tabAjuda/comoFunciona')" (keyup.enter)="Navegar('/tabs/tabAjuda/comoFunciona')"
              class="link" color="favorite">
              Como Funciona
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small" *ngIf="_tpUsuario === 1"
              (keyup.enter)="NavegarSiteInstitucional()" (click)="NavegarSiteInstitucional()" class="link"
              color="favorite">
              Painel Adminstrativo
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small" class="link" style="--color: var(--ion-color-favorite);"
              *ngIf="_tpUsuario === 0" (click)="NavegarSiteInstitucional()"
              (keyup.enter)="NavegarSiteInstitucional()">Seja
              nosso parceiro!
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small" color="favorite" (click)="AbrirMenuDesktop('Perfil')"
              (keyup.enter)="AbrirMenuDesktop('Perfil')" *ngIf="_usuario && _nmUsuario" title="Perfil"
              class="botaoPerfil">
              <ion-avatar *ngIf="_usuario.dlFotoUsuario" class="preview">
                <img src="{{ _urlAPI }}/storage/{{ _usuario.dlFotoUsuario }}" />
              </ion-avatar>
              <ion-avatar *ngIf="!_usuario.dlFotoUsuario" class="preview">
                <img src="./assets/profile_photo.jpg" />
              </ion-avatar>
              <ion-text style="padding-top: 2px;">
                {{_nmUsuario}}
              </ion-text>
            </ion-button>
            <ion-button tabindex="0" fill="clear" size="small" (click)="AcessarLogin()" (keyup.enter)="AcessarLogin()"
              *ngIf="_tpUsuario === 0" class="link" color="favorite">Entrar
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>
  <!-- <ion-router-outlet *ngIf="isDesktop === true" class="desktop-wrapper"></ion-router-outlet> -->
</div>
@defer (when !flMostrarAceiteCookies) {
<app-aceite-cookies-app *ngIf="flMostrarAceiteCookies"
  [ngClass]="{'aceiteCookiesDesktop animate__animated animate__fadeInUp animate__delay-2s': isDesktop, 'aceiteCookiesMobile animate__animated animate__fadeInUp animate__delay-2s': !isDesktop}"
  [isDesktop]="isDesktop"></app-aceite-cookies-app>
}
@defer (when !flMostrarWhatsapp) {
<div *ngIf="flMostrarWhatsapp"
  [ngClass]="{'whatsapp-bubble animate__animated animate__fadeInUp animate__delay-2s': isDesktop, 'whatsapp-bubble-mobile': !isDesktop}">
  <a tabindex="0" href={{_urlWhatsapp}} target="_blank" rel="nofollow noindex" style="text-decoration: none;"
    (click)="AbrirOutraAba(_urlWhatsapp)" (keyup.enter)="AbrirOutraAba(_urlWhatsapp)">
    <img
      [ngClass]="{'whatsapp-icon': isDesktop, 'whatsapp-icon-mobile animate__animated animate__fadeInUp animate__delay-2s': !isDesktop}"
      src="../assets/ico_whats.png" alt="whatsapp" loading="lazy">
  </a>
</div>
}
