import { Component, HostListener, NgZone, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform, IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import { format } from 'date-fns/format';
import { register } from 'swiper/element/bundle';
register();    // necessario para o swiper apenas 1 vez na carga (para slides)

import { AutocloseOverlaysAppService } from 'src/services/autoclose-overlays-app.service';
import { CookiesAppService } from 'src/services/cookies-app.service';
import { ControlePeriodoAppService, IPeriodoPesquisa } from 'src/services/controle-periodo-app.service';
import { DataLayerAppService } from 'src/services/data-layer-app.service';
import { FuncoesGeraisAppService } from 'src/services/funcoes-gerais-app.service';
import { ScreensizeAppService } from 'src/services/screensize-app.service';
import { SwupdaterAppService } from 'src/services/swupdater-app.service';

// LOCALE abaixo eh para funcionar o pipe currency do proprio angular
// vide tb provide: LOCALE_ID
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt)


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent implements OnDestroy {
  subscriptions: Array<Subscription> = [];
  isDesktop: boolean = false;
  flMostrarAceiteCookies: boolean = false;

  constructor(
    private platform: Platform,
    private zone: NgZone,
    private router: Router,
    private funcoesGeraisAppSrv: FuncoesGeraisAppService,

    private autocloseOverlaysAppSrv: AutocloseOverlaysAppService,
    private cookiesAppSrv: CookiesAppService,
    private controlePeriodoAppSrv: ControlePeriodoAppService,
    private dataLayerAppSrv: DataLayerAppService,
    private screensizeAppSrv: ScreensizeAppService,
    private swupdaterAppSrv: SwupdaterAppService,
  ) {
    // console.log('appComponent Constructor')
    this.platform.ready().then(() => {
      this.initializeApp();
      if (Capacitor.getPlatform() == 'web') {
        // console.log('detectou web')
        this.swupdaterAppSrv.checkForUpdates();
      }
    });
  }

  async initializeApp() {
    // comando abaixo serve para ficar com a cor adequada no status bar no ios
    if (Capacitor.getPlatform() == 'ios') {
      StatusBar.setStyle({ style: Style.Default });
    }
    if (Capacitor.getPlatform() != 'web') {
      // console.log('setBackgroundColor');
      StatusBar.setBackgroundColor({ color: "#092f8a" });
      StatusBar.setOverlaysWebView({
        overlay: false //make this overlay:false and try again
      });
    }
    this.subscriptions.push(this.screensizeAppSrv.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));
    this.InicializaPeriodoPesquisa();
    // console.log('initializeApp');
    // Nova rotina de Deeplink com @capacitor/app
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://passeioja.com.br/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".com.br").pop();
        // console.log('Deeplink - ', slug);
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
    // Vai obter a versão do APP do config.xml e salvar numa variável para uso qq solicitado
    await this.funcoesGeraisAppSrv.SetAppVersion();
    this.screensizeAppSrv.onResize(this.platform.width());
    await SplashScreen.hide();
    window.addEventListener('popstate', () => {
      // console.log('User clicked back button');
      this.autocloseOverlaysAppSrv.trigger();
    });
    this.subscriptions.push(this.router.events.subscribe((event: any): void => {
      // if (event instanceof NavigationStart) {
      //   // rotina para forçar o fechamento de todos os popovers ou modal
      //   // quando o usuário clicar no back button.
      //   if (event.navigationTrigger === 'popstate') {
      //     this.autocloseOverlaysAppSrv.trigger();
      //   }
      // }
      // registrar a pagina para o GoogleTagManager
      if (event instanceof NavigationEnd) {
        // console.log(event.url)
        this.dataLayerAppSrv.logPageView(event.url); //call our dataLayer service's page view method to ping home with the url value.
      }
    }));
    // aguardar 8 segundos para tratar o restante
    setTimeout(() => {
      this.LazyRoutines();
    }, 8000, []);
  }

  // rotina para forçar o fechamento de todos os popovers ou modal
  // quando o usuário clicar no back button.
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.autocloseOverlaysAppSrv.trigger();
  }


  // rotina para forçar a avaliação do tamanho da tela quando o usuário mudar o layout
  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    // console.log('width', event.target.innerWidth);
    // console.log('height', event.target.innerHeight);
    if (event.target.innerWidth) {   // precisa desse if pq o swiper seta para undefined e desarruma o isDesktop
      this.screensizeAppSrv.onResize(event.target.innerWidth);
    }
  }

  async ngOnDestroy() {
    // console.log('executou onDestroy de app.component')
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  // Rotinas para serem executadas somente após carga geral da pagina inicial
  // 8 segundos apos start do app
  LazyRoutines() {
    // tratamento de aceite de cookies
    if (!localStorage.getItem('PasseioJaApp:AcessoIp')) {
      this.flMostrarAceiteCookies = true;
      this.cookiesAppSrv.updateMostrarAceiteCookies(true);
    }
    this.subscriptions.push(this.cookiesAppSrv.onMostrarAceiteCookiesChange().subscribe(flMostrarAceiteCookies => {
      this.flMostrarAceiteCookies = flMostrarAceiteCookies;
    }));
  }

  InicializaPeriodoPesquisa() {
    // seta periodo casa ja tenha na localstorage e seja periodo futuro
    if (localStorage.getItem('PasseioJaApp:PeriodoPesquisa')) {
      let periodoPesquisa: IPeriodoPesquisa = JSON.parse(localStorage.getItem('PasseioJaApp:PeriodoPesquisa')!);
      if (periodoPesquisa
        && periodoPesquisa.dtInicialPesquisa != undefined
        && periodoPesquisa.dtInicialPesquisa != '') {
        let _dtHojeStr = format(new Date(), 'yyyyMMdd');
        if (_dtHojeStr <= periodoPesquisa.dtInicialPesquisa) {
          this.controlePeriodoAppSrv.publicarNovoPeriodo(periodoPesquisa);
        } else {
          localStorage.removeItem('PasseioJaApp:PeriodoPesquisa')
        }
      } else {
        localStorage.removeItem('PasseioJaApp:PeriodoPesquisa')
      }
    }
  }

}
