import { ErrorHandler, LOCALE_ID, enableProdMode, isDevMode } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Meta, Title, bootstrapApplication } from '@angular/platform-browser';
import { SwRegistrationOptions, provideServiceWorker } from '@angular/service-worker';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';

import { APP_ROUTES } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { GlobalErrorHandler } from './providers/globalErrorHandler';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(APP_ROUTES),
    provideAnimations(),
    provideHttpClient(),
    NativeGeocoder,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }, // interceptar problema com o service-worker
    // {
    //   provide: SwRegistrationOptions,       // para o service-worker (obter atualização automatica de versão)
    //   useFactory: () => ({ enabled: environment.production }),
    // },
    { provide: LOCALE_ID, useValue: 'pt-BR' }, // necessário para usar pipe currency do angular
    Title,
    Meta,

    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    // essas linhas abaixo foram copiadas do APP versao antiga - talvez nao precise
    {
      provide: SwRegistrationOptions,       // para o service-worker (obter atualização automatica de versão)
      useFactory: () => ({ enabled: !isDevMode() }),
    },
],
});
