import { Injectable, ViewChildren, QueryList } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root'
})
export class AutocloseOverlaysAppService {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
    private modalCtrl: ModalController,
    // reincluir caso use algum destes recursos no APP:
    // private actionSheetCtrl: ActionSheetController,
    // private popoverCtrl: PopoverController,
    // private menu: MenuController,
  ) {
    this.routerOutlets = new QueryList<IonRouterOutlet>();
  }

  // Este serviço é chamado no App.Component para solucionar o seguinte problema
  // Em qualquer modal, se o usuário clicar no back button, no celular ou no browser,
  // esta rotina vai forçar o fechamento do modal
  // tem uma rotina tb que precisa ser incluída no onInit de cada modal
  async trigger() {
    // console.log('backbutton triggered');

    // close modal
    try {
      const element = await this.modalCtrl.getTop();
      if (element) {
        element.dismiss();
        return;
      }
    } catch (error) {
      console.log('Erro no autoclose-overlays service', error);
    }

    //   // close side menua
    //   try {
    //     const element = await this.menu.getOpen();
    //     if (element !== null) {
    //       this.menu.close();
    //       return;
    //     }
    //   } catch (error) {
    //   }
    // }

    // // close action sheet
    // try {
    //   const element = await this.actionSheetCtrl.getTop();
    //   if (element) {
    //     element.dismiss();
    //     return;
    //   }
    // } catch (error) {
    // }

    // // close popover
    // try {
    //   const element = await this.popoverCtrl.getTop();
    //   if (element) {
    //     element.dismiss();
    //     return;
    //   }
    // } catch (error) {

  }

}
