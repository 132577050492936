import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { ModalController, Platform, IonTabs, IonButton, IonAvatar, IonTabBar, IonLabel, IonIcon, IonTabButton, IonBadge, IonHeader, IonToolbar, IonRow, IonCol, IonTitle, IonText } from "@ionic/angular/standalone";
import { addIcons } from 'ionicons';
import { book, helpCircle, homeSharp, person } from 'ionicons/icons';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AlertAppService } from 'src/services/alert-app.service';
import { ClienteAppService } from 'src/services/cliente-app.service';
import { CookiesAppService } from 'src/services/cookies-app.service';
import { ControleLoginAppService } from 'src/services/controle-login-app.service';
import { FuncoesGeraisAppService } from 'src/services/funcoes-gerais-app.service';
import { NetworkAppService } from 'src/services/network-app.service';
import { ScreensizeAppService } from 'src/services/screensize-app.service';
import { TemaSwitchAppService } from 'src/services/tema-switch-app.service';
import { UsuarioAppService } from 'src/services/usuario-app.service';

import { UsuarioModel } from 'src/models/usuarioModel';
import { RegiaoTuristicaModel } from 'src/models/regiaoTuristicaModel';

import { environment } from 'src/environments/environment';
import { AceiteCookiesAppComponent } from 'src/components/aceite-cookies-app/aceite-cookies-app.component';

export interface IFotoUsuario {
  uid: string;
  nmUsuarioContraparte: string;
  dlFotoUsuario: string;
}

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [

    IonText, IonTitle, IonCol, IonRow, IonToolbar, IonHeader, IonBadge, IonTabButton,
    IonIcon, IonTabBar, IonAvatar, IonButton, IonTabs, IonLabel,
    NgClass, NgIf,
    AceiteCookiesAppComponent,
  ],
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];

  _tpUsuario: number = 0;
  _nmUsuario: string = '';
  _urlAPI: string = environment.url_api;

  _msgOffline: string[] = ['Parece que você está sem Internet no momento.',
    'Durante este período o APP poderá não funcionar adequadamente.']
  _flOnline: boolean = false;
  _qtNetworksChanges: number = 0;
  _usuario: UsuarioModel = new UsuarioModel();
  _flApresentarComoFunciona: boolean = true;

  _urlWhatsappBase: string = "https://wa.me/";
  _nuWhatsapp: string = "5511971531525";
  _urlWhatsapp: string = "";
  flMostrarWhatsapp: boolean = false;

  _flClienteElegivelCuponsPromocao: boolean = false;

  isDesktop: boolean = false;
  flMostrarAceiteCookies: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private router: Router,
    // public zone: NgZone,

    private alertAppSrv: AlertAppService,
    private clienteAppSrv: ClienteAppService,
    private cookiesAppSrv: CookiesAppService,
    private controleLoginAppSrv: ControleLoginAppService,
    private funcoesGeraisAppSrv: FuncoesGeraisAppService,
    private networkAppSrv: NetworkAppService,
    private screensizeAppSrv: ScreensizeAppService,
    private temaSwitchAppSrv: TemaSwitchAppService,
    private usuarioAppSrv: UsuarioAppService,
  ) {
    addIcons({
      homeSharp, book, person, helpCircle
    });
    // console.log('Constructor tabs.page')
    if (localStorage.getItem('PasseioJaApp:device')) {
      localStorage.removeItem('PasseioJaApp:device');
    }
    this.platform.ready().then(async () => {
      const _ident = await this.funcoesGeraisAppSrv.GetDadosDevice();
      // console.log('dados device - ', _ident);
      localStorage.setItem('PasseioJaApp:device', _ident);
      // console.log(_ident);
      // fica escutando mudança de tamanho da tela, se é desktop ou celular
      this.subscriptions.push(this.screensizeAppSrv.isDesktopView().subscribe(isDesktop => {
        if (this.isDesktop && !isDesktop) {
          window.location.reload();
        }
        this.isDesktop = isDesktop;
        // caso esteja entrando a primeira vez no APP ou no site, não vai ter a localStorage abaixo
        // DESISTI DE APRESENTAR O MODAL DE INTRODUCAO
        //   ==> SUBSTITUIDO POR CHAMAR O HELP-PONTUAL-MODAL no tab-cliente-home
        // if (!localStorage.getItem('PasseioJaApp:CampanhaOrigem')) {
        //   this.VisualizarIntroducao();
        // }
      }));
      let _linkAtual = environment.urlPasseioJa + this.router.url;
      this._urlWhatsapp = `${this._urlWhatsappBase}${this._nuWhatsapp}?text=Link de referência - ${encodeURIComponent(_linkAtual || '')}`;
      // Subscription para cada mudanca de rota
      this.subscriptions.push(this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        let _urlAux: string = this.router.url;
        if (this.router.url.indexOf('/pedido/new') > -1) {
          // qdo estiver na url de novo pedido transforma na url do passeio de origem do pedido
          let _urlAux2 = this.router.url.substring(0, this.router.url.indexOf('/pedido/new'));
          _urlAux = _urlAux2.replace('/reserva/','/passeio/');
        }
        let _linkAtual = environment.urlPasseioJa + _urlAux;
        this._urlWhatsapp = `${this._urlWhatsappBase}${this._nuWhatsapp}?text=Link de referência - ${encodeURIComponent(_linkAtual || '')}`
      }))
      // fica escutando mudança de tamanho da tela, se é desktop ou celular
      this.subscriptions.push(this.screensizeAppSrv.getObservableWidth().subscribe(novoWidth => {
        if (novoWidth >= 768
          && novoWidth < 850) {
          this._flApresentarComoFunciona = false;
        } else {
          this._flApresentarComoFunciona = true;
        }
      }));
    });
    // aguardar 9 segundos para tratar o restante
    setTimeout(() => {
      this.LazyRoutinesTabs();
    }, 8000, []);

  }

  async ngOnInit() {
    // console.log('onInit tabsPage')
    this._usuario = this.controleLoginAppSrv.ObterUsuarioLogado();
    if (this._usuario) {     // pode vir o objeto vazio
      if (this._usuario.uid) {
        this._tpUsuario = this._usuario.tpUsuario;
        if (this._usuario.tpUsuario == 1) {
          this._nuWhatsapp = '5513997860027';     // agencia usa whatsapp Clarissa
        } else {
          this._nuWhatsapp = '5511971531525';
        }
      }
      // Necessário subscribe do nome para o caso do usuário mudar seu nome e refletir na página automaticamente
      this.subscriptions.push(this.controleLoginAppSrv.observableNomeUsuarioLogged().subscribe(nmUsuLogged => {
        this._usuario.nmUsuario = nmUsuLogged;
        // Monta nome do usuario para página apenas com primeiro e segundo nome
        let _arrayPedaco = this._usuario.nmUsuario.split(' ');
        this._nmUsuario = _arrayPedaco[0];
        if (_arrayPedaco.length > 1) {
          this._nmUsuario = this._nmUsuario + ' ' + _arrayPedaco[1];
        }
        // console.log('pegou novo nome - ', this._usuario.nmUsuario)
        // console.log('pegou novo nome - ', this._nmUsuario)
      }));
      this.subscriptions.push(this.controleLoginAppSrv.observableFotoUsuarioLogged().subscribe(dlFotoLogged => {
        this._usuario.dlFotoUsuario = dlFotoLogged;
        // console.log('pegou nova foto - ', this._usuario.dlFotoUsuario)
      }));
      this.subscriptions.push(this.controleLoginAppSrv.observableUidUsuarioLogged().subscribe(async uidUsuario => {
        if (uidUsuario) {
          // console.log('Pegou novamente o usuario', uidUsuario)
          this._usuario = this.controleLoginAppSrv.ObterUsuarioLogado();
          if (this._usuario && this._usuario.uid) {
            this.TratarElegibilidadePromocoesCliente();
            this._tpUsuario = this._usuario.tpUsuario;
            if (this._usuario.tpUsuario == 1) {
              this.temaSwitchAppSrv.temaGuia();
              this._nuWhatsapp = '5513997860027';     // agencia usa whatsapp Clarissa
            } else {
              this.temaSwitchAppSrv.temaCliente();
              this._nuWhatsapp = '5511971531525';
            }
            if (this._usuario.tpUsuario == 2) {
              this._flClienteElegivelCuponsPromocao = await this.clienteAppSrv.obterElegibilidadePromocaoCliente(this._usuario.uid);
            } else {
              this._flClienteElegivelCuponsPromocao = false;
              this.clienteAppSrv.definirElegibilidadeCuponsPromocaoCliente(false);
            }
          } else {
            this.RetiraLogin();
          }
        } else {
          this.RetiraLogin();
        }
      }));

      if (!this._usuario.uid) {
        if (localStorage.getItem('PasseioJaApp:uid')
          && localStorage.getItem('PasseioJaApp:token')) {
          // tem o token, ou seja, estava logado, mas nao tem os dados do usuário:
          // chama a rotina para ir na API, mas segue adiante,
          //   ==> o retorno vai vir atraves dos observables
          await this.usuarioAppSrv.ObterLogadoAPI(localStorage.getItem('PasseioJaApp:uid')!);
        } else {
          this._tpUsuario = 0;
        }
      }
      setTimeout(async () => {
        // console.log('vai obter status tabs')
        this._flOnline = await this.networkAppSrv.initializeNetworkEvents(Capacitor.getPlatform());
        if (!this._flOnline) {
          this.alertAppSrv.toast(`Você está sem internet`, 'bottom', 'warning', 1500)
          this.alertAppSrv.alert('Offline', this._msgOffline);
          this._flOnline = false;
        }
      }, 1000, []);
    }
  }


  async ngOnDestroy() {
    if (Capacitor.getPlatform() != 'web') {
      await this.networkAppSrv.removeListeners();
    }
    this.subscriptions.forEach((sub: Subscription) => { sub.unsubscribe(); });
  }

  // Rotinas para serem executadas somente após carga geral da pagina inicial
  // 8 segundos apos start do app
  LazyRoutinesTabs() {
    // tratamento de aceite de cookies e mostrar botão o Whatsapp
    if (!localStorage.getItem('PasseioJaApp:AcessoIp')) {
      this.flMostrarAceiteCookies = true;
      this.cookiesAppSrv.updateMostrarAceiteCookies(true);
      this.flMostrarWhatsapp = false;
    } else {
      // vai mostrar bubble Whatsapp qdo nao precisa mostrar msg cookies
      this.flMostrarWhatsapp = true;
    }
    this.subscriptions.push(this.cookiesAppSrv.onMostrarAceiteCookiesChange().subscribe(flMostrarAceiteCookies => {
      this.flMostrarAceiteCookies = flMostrarAceiteCookies;
      if (this.flMostrarAceiteCookies == false) {
        this.flMostrarWhatsapp = true;
      }
    }));
    this.funcoesGeraisAppSrv.configCustomCreditCard();
    // Liga o subscription para avisar se esta off line
    // estah fazendo somente 8 seg depois da carga, pois
    // no onInit já da a msg se estiver offline
    this.subscriptions.push(this.networkAppSrv.onNetworkChange().subscribe((status: boolean) => {
      this._qtNetworksChanges = this._qtNetworksChanges + 1;
      if (status == false) {
        // console.log('tabs-page - offline')
        this.alertAppSrv.toast(`Você está sem internet`, 'bottom', 'warning', 1500)
        this.alertAppSrv.alert('Offline', this._msgOffline);
        this._flOnline = false;
      } else {
        // console.log('tabs-page - online');
        this._flOnline = true;
      }
    }));
  }

  // Modal excluído - vide pasta excluidos (em 01/04/2022)
  // async VisualizarIntroducao() {
  //   let _modalClass: string = ''
  //   let _isDesk = this.isDesktop;
  //   if (_isDesk) {
  //     _modalClass = 'modalIntroBrowser'
  //   } else {
  //     _modalClass = 'modalIntroApp'
  //   }
  //   const modal: HTMLIonModalElement = await this.modalController.create({
  //     component: IntroducaoModalPage,
  //     componentProps: {
  //       'isDesktop': _isDesk,
  //     },
  //     mode: 'md',
  //     cssClass: _modalClass      // modalClass definida no global.scss para config.tamanho do modal
  //   });
  //   modal.onDidDismiss().then((detail) => {
  //     if (detail !== null) {
  //     }
  //   });
  //   await modal.present();
  // }

  RetiraLogin() {
    this._usuario = new UsuarioModel();
    this._nmUsuario = '';
    this._tpUsuario = 0;
    this.temaSwitchAppSrv.temaCliente();
    this._nuWhatsapp = '5511971531525';
    // mesmo quando não tem login vai ficar como elegivel para cupons de promoção
    this._flClienteElegivelCuponsPromocao = true;
    this.clienteAppSrv.definirElegibilidadeCuponsPromocaoCliente(true);
  }


  // Verifica elegibilidade de cliente para promoções e atualiza observable
  // para uso nas demais páginas (via cliente service)
  async TratarElegibilidadePromocoesCliente() {
    this._flClienteElegivelCuponsPromocao = false;
    this.clienteAppSrv.definirElegibilidadeCuponsPromocaoCliente(false);
    this._usuario = this.controleLoginAppSrv.ObterUsuarioLogado();
    if (this._usuario && this._usuario.uid) {
      if (this._usuario.tpUsuario == 2) {
        this._flClienteElegivelCuponsPromocao = await this.clienteAppSrv.obterElegibilidadePromocaoCliente(this._usuario.uid);
      }
    }
  }


  // só é usado em desktop
  async AbrirMenuDesktop(tpMenu: string) {
    // estrategia para lazy load de modal - observar o recurso de obter o componente usando getComponent()
    const { MenuDesktopModalPage } = await import('../../modals/menu-desktop-modal/menu-desktop-modal.page');
    let _modalClass: string = `modalMenuDesktop${tpMenu}`;
    // console.log(_modalClass)
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: MenuDesktopModalPage,
      componentProps: {
        tpUsuario: this._usuario.tpUsuario,
        usuario: this._usuario,
        tpMenu: tpMenu
        // qtMsgNaoLidas: this._qtMsgNaoLidas,
        // qtNotificacoesPedido: this._qtNotificacoesPedido
      },
      mode: 'md',
      cssClass: _modalClass      // modalClass definida no global.scss para config.tamanho do modal
    });
    modal.onDidDismiss().then((detail) => {
      // if (detail !== null && detail.data && detail.data.retModal) {
      //   // console.log(detail.data)
      // }
    });
    await modal.present();
  }


  async NavegarSiteInstitucional() {
    let opts: string = "location=yes,clearcache=yes,hidespinner=no";
    // this.iab.create(`${environment.urlSiteGeren}`, '_system', opts);
    await Browser.open({ url: environment.urlSiteGeren });
  }

  async NavegarSiteBlog() {
    let _link = '';
    if (localStorage.getItem('PasseioJaApp:uid')
      && localStorage.getItem('PasseioJaApp:token')) {
      _link = `${environment.urlSiteBlog}/posts/registraToken/${localStorage.getItem('PasseioJaApp:token')}/${localStorage.getItem('PasseioJaApp:uid')}`;
    } else {
      _link = environment.urlSiteBlog;
    }
    await Browser.open({ url: _link });
  }

  AcessarLogin() {
    // localStorage.setItem('PasseioJaApp:proxRoute', this.router.url);
    this.router.navigateByUrl('tabs/login');
  }

  Navegar(rota: string) {
    // console.log(rota)
    this.router.navigateByUrl(rota);
  }

  Home() {
    if (localStorage.getItem('PasseioJaApp:regiaoTuristicaSelecionada')) {
      localStorage.removeItem('PasseioJaApp:regiaoTuristicaSelecionada');
    }
    this.router.navigateByUrl('/tabs/passeios/seleciona_regiao');
  }

  NavegarPasseiosDesktop() {
    if (this.router.url.includes('/tabs/passeios/cidade')) {
      if (localStorage.getItem('PasseioJaApp:regiaoTuristicaSelecionada')) {
        // console.log('removeu selecionada')
        localStorage.removeItem('PasseioJaApp:regiaoTuristicaSelecionada');
      }
      this.router.navigateByUrl(`/tabs/passeios/seleciona_regiao`);
    } else {   // se está no passeio detalhe ou reserva etc...
      if (localStorage.getItem('PasseioJaApp:regiaoTuristicaSelecionada')) {
        let _regiaoAux: RegiaoTuristicaModel = JSON.parse(localStorage.getItem('PasseioJaApp:regiaoTuristicaSelecionada')!);
        this.router.navigateByUrl(`/tabs/passeios/cidade/${_regiaoAux.nmLinkRotaRegiao}`,
          {
            state: {
              regiaoTuristicaSelecionada: _regiaoAux
            }
          });
      } else {
        this.router.navigateByUrl(`/tabs/passeios/seleciona_regiao`);
      }
    }
  }

  // essa rotina eh executada qdo clicado no tab Passeios no MOBILE (antes de executar o tab):
  // se já estiver na lista de passeios de uma cidade, deve voltar para selecionar Regiao
  // (isso será feito já que sera deletada a regiao selecionada)
  // DEVIDO A NOVA ESTRUTURA DE ROTAS - ao clicar no tab Passeios no Mobile, vai sempre voltar na Home
  // (lista de regioes)
  navegarPasseios() {
    // console.log(this.router.url)
    if (this.router.url.includes('/tabs/passeios/cidade')) {
      if (localStorage.getItem('PasseioJaApp:regiaoTuristicaSelecionada')) {
        // console.log('removeu selecionada')
        localStorage.removeItem('PasseioJaApp:regiaoTuristicaSelecionada');
      }
    }
  }


  async AbrirOutraAba(link: string) {
    let opts: string = "location=yes,clearcache=yes,hidespinner=no";
    await Browser.open({ url: link });
  }

}
