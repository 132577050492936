import { Routes } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';
import { AuthGuardAppService } from 'src/services/auth-guard-app.service';

const _tpLogin: string | null = localStorage.getItem('PasseioJaApp:tpLogin') ? localStorage.getItem('PasseioJaApp:tpLogin') : '';

// loadComponent: () =>   indica lazy load apenas de um componente
// loadChildren: () =>    indica lazy load da arvore
// foi incluida a rota tabmobile pq o google usa como pagina indexada tambem
export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: `${_tpLogin && _tpLogin == 'guia' ? '/tabs/tabGuiaHome' : '/tabs/passeios/seleciona_regiao'}`,
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'passeios',
        loadChildren: () => import('./tabs-passeios.routes').then(r => r.TABS_PASSEIOS_ROUTES)
      },
      {
        path: 'tabClientePedidos',
        loadChildren: () => import('./tabs-cliente-pedidos.routes').then(r => r.TABS_CLIENTE_PEDIDOS_ROUTES)
      },
      {
        path: 'tabPerfil',
        loadChildren: () => import('./tabs-perfil.routes').then(r => r.TABS_PERFIL_ROUTES)
      },
      {
        path: 'tabAjuda',
        loadChildren: () => import('./tabs-ajuda.routes').then(r => r.TABS_AJUDA_ROUTES)
      },
      {
        path: 'tabGuiaHome',
        loadChildren: () => import('./tabs-guia.routes').then(r => r.TABS_GUIA_ROUTES)
      },
      {
        path: 'cadastroUsuario/:nmTipoUsuario',
        loadComponent: () => import('./cadastro-usuario/cadastro-usuario.page').then(p => p.CadastroUsuarioPage),
        canActivate: [AuthGuardAppService],
        data: { roles: ['livre'] }
      },
      {
        path: 'cpnha/:uid',
        loadComponent: () => import('./campanha/campanha.page').then(p => p.CampanhaPage),
        canActivate: [AuthGuardAppService],
        data: { roles: ['livre'] }
      },
      {
        path: 'cupomAssociar/:uid',
        loadComponent: () => import('./cupom-associar/cupom-associar.page').then(p => p.CupomAssociarPage),
        canActivate: [AuthGuardAppService],
        data: { roles: ['livre'] }
      },
      {
        path: 'login',
        loadComponent: () => import('./login/login.page').then(p => p.LoginPage),
        canActivate: [AuthGuardAppService],
        data: { roles: ['livre'] }
      },
      {
        path: 'loginConfirmaCadastro/:uid/:token',
        loadComponent: () => import('./login-confirma-cadastro/login-confirma-cadastro.page').then(p => p.LoginConfirmaCadastroPage),
        canActivate: [AuthGuardAppService],
        data: { roles: ['livre'] }
      },
      {
        path: 'loginEsqueciSenha',
        loadComponent: () => import('./login-esqueci-senha/login-esqueci-senha.page').then(p => p.LoginEsqueciSenhaPage),
        canActivate: [AuthGuardAppService],
        data: { roles: ['livre'] }
      },
      {
        path: 'loginRecuperaSenha/:email/:token',
        loadComponent: () => import('./login-recupera-senha/login-recupera-senha.page').then(p => p.LoginRecuperaSenhaPage),
        canActivate: [AuthGuardAppService],
        data: { roles: ['livre'] }
      },
    ]
  },
  {
    path: 'tabsmobile',
    component: TabsPage,
    children: [
      {
        path: 'passeios',
        loadChildren: () => import('./tabs-passeios.routes').then(r => r.TABS_PASSEIOS_ROUTES)
      },
      {
        path: 'tabClientePedidos',
        loadChildren: () => import('./tabs-cliente-pedidos.routes').then(r => r.TABS_CLIENTE_PEDIDOS_ROUTES)
      },
      {
        path: 'tabPerfil',
        loadChildren: () => import('./tabs-perfil.routes').then(r => r.TABS_PERFIL_ROUTES)
      },
      {
        path: 'tabAjuda',
        loadChildren: () => import('./tabs-ajuda.routes').then(r => r.TABS_AJUDA_ROUTES)
      },
      {
        path: 'login',
        loadComponent: () => import('./login/login.page').then(p => p.LoginPage),
        canActivate: [AuthGuardAppService],
        data: { roles: ['livre'] }
      },
    ]
  },

  {
    path: '**',
    redirectTo: `${_tpLogin && _tpLogin == 'guia' ? '/tabs/tabGuiaHome' : '/tabs/passeios/seleciona_regiao'}`,
    pathMatch: 'full'
  },

];
